// Angular Imports
import { NgModule } from '@angular/core';

// Project Imports
import { AspenNavigationModule, AspenNavItem } from '@core/navigation/aspen-navigation.module';

const navItems: AspenNavItem[] = [
    {
        id: 'vizio-dmp',
        label: 'Segment Manager',
        parent: 'vizio-dmp',
        order: 10,
        type: 'basic',
        link: '/vizio-dmp',
        dataCy: 'sidebarItemSegmentManager'
    }
];

@NgModule({
    imports: [AspenNavigationModule.forChild(navItems)]
})
export class VizioDMPNavigationModule {}
