// Angular Imports
import { Inject, Injectable } from '@angular/core';

// Project Imports
import { SignalStateServiceBase } from '@core/state-service/signal-state-service.base';
import { AspenNavItem, INIT_NAV_STATE } from './aspen-navigation.module';

export interface NavigationConfig {
    id: string;
    label: string;
    icon: string;
    requiredPermissions?: string[];
    requiredFeatureFlags?: string[];
    dataCy?: string;
    hiddenWithFeatureFlags?: string[];
    link?: string;
    children: AspenNavItem[];
}

export interface AspenNavigationState {
    sideNavConfig: NavigationConfig[];
    opened: boolean;
    userNavConfig: Omit<AspenNavItem, 'parent'>[];
}

@Injectable({
    providedIn: 'root'
})
export class AspenNavigationStateService extends SignalStateServiceBase<AspenNavigationState> {
    public navConfig = this.state.sideNavConfig;
    public userNavConfig = this.state.userNavConfig;
    public opened = this.state.opened;

    constructor(@Inject(INIT_NAV_STATE) initialState: AspenNavigationState) {
        super(initialState);
    }

    public toggleNav(open: boolean = null) {
        this.setState({ opened: open === null ? !this.state().opened : open });
    }
}
