// Angular Imports
import { Component, OnInit } from '@angular/core';
import {
    ActivationStart,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    Router
} from '@angular/router';

// Third Party Imports
import { filter, map, Observable } from 'rxjs';

// Project Imports
import { GoogleAnalyticsService } from '@core/services/google-analytics.service';
import { EasterEggService } from '@shared/easter-eggs/easter-egg.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public isLoading$ = this.router.events.pipe(
        filter((event) => {
            return (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError ||
                event instanceof ActivationStart
            );
        }),
        map((event) => {
            if (event instanceof ActivationStart) {
                return !!event['snapshot']?.data?.showLoader;
            } else {
                return false;
            }
        })
    );

    public isSpooky$: Observable<boolean> = this.easterEggService.isSpooky$;

    constructor(
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService,
        private easterEggService: EasterEggService
    ) {}

    public ngOnInit() {
        this.googleAnalyticsService.init();
    }
}
