<mat-sidenav-container
    class="aspen-side-nav"
    [ngClass]="(showNav() && mobile) === true ? 'aspen-overlap' : ''"
>
    <mat-sidenav #drawer mode="side" [opened]="showNav()">
        <ng-container>
            <!-- Logo -->
            <div class="aspen-side-nav__logo-container">
                <a tabindex="0" routerLink="/home" class="aspen-side-nav__logo-link">
                    <img
                        class="aspen-side-nav__logo"
                        src="../../../../assets/images/logo/VIZIO_LOGO_WHITE_NO_PAD.png"
                        alt="Vizio logo linking to the home page"
                    />
                </a>
            </div>

            <div class="aspen-side-nav__aspen-container">
                <span class="aspen-side-nav__icon-container">
                    @if ((isSpooky$ | async) === false) {
                        <mat-icon
                            class="aspen-side-nav__icon"
                            [inline]="true"
                            svgIcon="vizio:aspen-filled"
                            (click)="iconClicked()"
                        ></mat-icon>
                    } @else {
                        <mat-icon
                            class="aspen-side-nav__icon aspen-side-nav__icon--spooky"
                            [inline]="true"
                            svgIcon="vizio:aspen-pumpkin"
                            (click)="iconClicked()"
                        ></mat-icon>
                    }
                </span>
                <span class="aspen-side-nav__title-container">
                    <h1 class="aspen-side-nav__aspen-title">ASPEN</h1>
                </span>
            </div>
        </ng-container>
        <mat-accordion>
            @for (item of navConfig(); track item; let i = $index) {
                <!-- COLLAPSABLE -->
                @if (item.children.length) {
                    <div *showWithEitherFeatureFlag="item.requiredFeatureFlags">
                        <mat-expansion-panel
                            *showWithEitherPermission="item.requiredPermissions"
                            class="aspen-side-nav__collapsable"
                            [expanded]="item.id === openedSignal()"
                        >
                            <mat-expansion-panel-header
                                class="aspen-side-nav__item aspen-side-nav__collapsable-item"
                                [attr.data-cy]="item.dataCy"
                            >
                                <mat-panel-title class="aspen-side-nav__item-title">
                                    <mat-icon
                                        class="aspen-side-nav__item-icon"
                                        [svgIcon]="item.icon"
                                    ></mat-icon>
                                    {{ item.label }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <!-- fix this -->
                            <mat-list role="list" class="aspen-side-nav__child-list">
                                @for (child of item.children; track child) {
                                    <div *showWithEitherFeatureFlag="child.requiredFeatureFlags">
                                        <a
                                            tabindex="0"
                                            [routerLink]="child.link"
                                            routerLinkActive="active"
                                            class="aspen-side-nav__item aspen-side-nav__collapsable-child-item-link"
                                            [attr.data-cy]="child.dataCy"
                                        >
                                            <mat-list-item
                                                *showWithEitherPermission="
                                                    child.requiredPermissions
                                                "
                                                class="aspen-side-nav__collapsable-child-item"
                                                role="listitem"
                                            >
                                                <span class="nav-item-name">{{ child.label }}</span>
                                            </mat-list-item>
                                        </a>
                                    </div>
                                }
                            </mat-list>
                        </mat-expansion-panel>
                    </div>
                }
            }
        </mat-accordion>
    </mat-sidenav>
</mat-sidenav-container>
<div
    [ngClass]="(showNav() && mobile) === true ? 'block' : 'hidden'"
    (click)="toggleNav()"
    class="aspen-side-nav__overlay"
></div>
